<template>
  <div>
    <h1>ADMIN - Config Keys</h1>

    <div class="mb-5">
      <config-keys-form />
    </div>

    <div class="list-group">
      <div v-for="key in configKeys" :key="key.id" class="list-group-item">
        {{ key.config_key }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useAuth0 } from '@auth0/auth0-vue'
import ConfigKeysForm from "@/views/admin/ConfigKeysForm.vue";
const auth0 = useAuth0()
const store = useStore()
const configKeys = computed(() => store.getters['admin/configKeys'])


loadConfig()

function loadConfig() {
  store.dispatch('admin/getConfigKeys', {auth0})
      .then(keys => console.log(keys))
      .catch(e => {
        alert(e.message || JSON.stringify(e))
      })
}
</script>

<style lang="scss" scoped>

</style>
